@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

.mainlogo-h1 {
	background: -webkit-linear-gradient(144deg, #af40ff, pink 50%, #00ddeb);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: #fff;
	font-weight: 500;
	font-family: "Play";
	font-size: 2rem;
}

.main-logo{
	margin-top: 60px;
}

/* button animations */
.explore-button-a {
	text-decoration: none;
}

.explore-btn {
	margin-top: 5%;
}

.button-explore {
	cursor: pointer;
	background: white;
	border-radius: 50px;
	background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
	animation: gradient 2.5s infinite 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.2)
		forwards;
	color: black;
	padding-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 50px;
}

@keyframes gradient {
	0% {
		background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
	}

	10% {
		background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
	}

	20% {
		background: linear-gradient(90deg, #fdbb2d 0%, #3a1c71 100%);
	}

	30% {
		background: linear-gradient(90deg, #fdbb2d 0%, #22c1c3 100%);
	}

	40% {
		background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
	}

	50% {
		background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 100%);
	}

	60% {
		background: linear-gradient(90deg, #0700b8 0%, #00ff88 100%);
	}

	70% {
		background: linear-gradient(90deg, #3f2b96 0%, #a8c0ff 100%);
	}

	80% {
		background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
	}

	90% {
		background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
	}

	100% {
		background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
	}
}
