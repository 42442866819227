@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

.navbar{
    margin: 0 7%;
    border-radius: 10px;
    /* background: rgba(12, 10, 29, 0.9); */
    background-color: transparent;
    color: white;
    box-shadow: 1px 1px 8px 3px black;
    position: relative;
    top : 20px;
    padding-top: 0;
    padding-bottom: 0;
}


.navbar-nav{
    color: white;
    display: flex;
    align-items: center;
}

.nav-link{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navitem {

    display: flex;
    align-items: center;
    justify-content: center;

}

.navitem{
    color: white;
    margin: 0 20px  0;
    font-size: 1.2rem;
    font-family: 'Play';
}

/* .navitem:hover{
    color:pink;
    border-bottom: 2px solid pink;
} */

/* .active {
   border-bottom: 2px solid rgb(127, 62, 144);      
} */

.login-navitem{
    position:relative;
    /* top:10px; */
    display: inline-block;
    /* padding: 15px 30px; */
    border: 1px solid gray;
    border-color: #2196f3;
    border-radius: 10px;
    padding:  10px;
    /* color: #2196f3; */
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    overflow: hidden;
    transition: 0.2s
    

}
.login-navitem:hover{
    color: #255784;
    background: #93eff2;
    box-shadow: 0 0 7px #93eff2, 0 0 30px #93eff2, 0 0 60px #93eff2;
    transition-delay: 0.3s;
}

.menu-img{
    height: 35px;
    width: 35px;
}


/* Title Design */
.logo-bottom{
    font-family: Arial, Helvetica, sans-serif;   
    padding:0 0 10px 15px;
    border-bottom: 5px solid #0081B4;
    border-left: 5px solid #0081B4;
    display: inline;
}

.logo-top{
    padding:0 20px 0 0;
    border-right: 5px solid #0081B4;
    border-top: 5px solid #0081B4;   
}

.title{
    color: white;
    font-size: 2rem;
    margin-bottom: 10px;
    margin-left: 20px;
    font-family: 'Play';
    font-weight: bold;
    text-decoration: none;
}

.logo {

    pointer-events: none;
    
}

.navbar-brand:hover{
    color: white;
}

@media screen and (max-width: 800px) {
    
    .navbar-brand {

        width: 40%;

    }
}