.sponsors-heading {
	font-family: "Play";
	font-style: normal;
	font-weight: 700;
	line-height: 98px;
	color: #fff;
}

.sponsor-card {
	margin: 4%;
	width: 18rem;
	height: fit-content;
	border-radius: 20px;
	z-index: 20;
}

.sponsor-card-img {
	border-radius: 15px;
	border: #5661ff 2px solid;
}

.sponsors-container-main {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2%;
	pointer-events: none;
}

/* .sponsor-card {

    margin: 20px auto;
    width: 250px;
    height: fit-content;
    border-radius: 20px;

}

.sponsor-card-img {

    border-radius: 15px;
    border: #5661ff 2px solid;

}

.sponsor-name {

    font-family: 'Urbanist';
    text-align: center;
    font-style: normal;
    font-size: 2vw;
    color: white;
    margin-top: 10px;
    padding: 2px;
    border-radius: 20px;
    border: #5661ff 1px solid;

} */
