.event-heading {
    color: #ffffff;
    font-family: "Play";
    font-style: normal;
    font-weight: 700;
    font-size: 6rem;
    margin-top: 50px;
    max-width: fit-content;
    background: -webkit-linear-gradient(
        -86deg,
        #6d99f7 5%,
        #8c8dc4 53%,
        #0044ff 91%
    );
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    color: #111111;
    z-index: 10;
}

@media screen and (max-width: 800px) {
    .event-heading {
        font-size: 3rem;
        -webkit-text-stroke: 3px transparent;
    }
}
