body {
    background-image: radial-gradient(
        circle,
        #00122c,
        #001338,
        #091243,
        #1d0d4c,
        #310051
    );
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 0.2em;
}

body::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(118, 0, 120);
}

.bg-gradients {
    position: absolute;
    height: fit-content;
    inset: 0;
    z-index: -3;
    overflow: hidden;
}

/* .bg-gradient-1 {

  position: relative;
  width: 100%;
  height: calc(100vh+30px);
  height: calc(100vh+30px);
  background: linear-gradient(242.86deg, #9B00FF 0.9%, rgba(5, 25, 55, 0) 100%, #051937 100%);
  filter: blur(50px);

}

.bg-gradient-2 {

  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(296.82deg, #9900ff 0%, rgba(5, 25, 55, 0) 100%, #051937 100%);
  filter: blur(50px);
  margin-top: -30px;

}

.bg-gradient-3 {

  position: relative;
  width: 100%;
  height: calc(100vh + 30px);
  background: linear-gradient(242.86deg, #9B00FF 0.9%, rgba(5, 25, 55, 0) 100%, #051937 100%);
  filter: blur(50px);
  margin-top: -30px;

}

.bg-gradient-4 {

  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(296.82deg, #9B00FF 0%, rgba(5, 25, 55, 0) 100%, #051937 100%);
  filter: blur(50px);
  margin-top: -30px;

} */
