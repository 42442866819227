.footer-main {
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(
        260deg,
        #022976 0.58%,
        rgba(64, 168, 163, 0.3) 100%
    );
    border-bottom: #fa00fe 2px solid;
    overflow: hidden;
}

.footer-anantya-logo {
    width: 40%;
}

.footer-links-main {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: #99ffef 1px solid;
    border-right: #99ffef 1px solid;
}

.footer-links {
    color: #fff;
    text-decoration: none;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.design-with-love {
    box-sizing: border-box;
    border-top: #022976 1px solid;
}

.design-with-love-text {
    color: #fff;
    text-align: center;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    margin: 5px 0px;
}

.footer-contact-us-div {
    margin-left: 15.4%;
}

.footer-contact-us {
    color: #99ffef;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 10px 0;
}

.footer-icons-main {
    margin-left: 15.4%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
}

.footer-contact-icon {
    width: 25px;
    height: 25px;
    align-self: center;
}

.footer-contact-us-icons {
    width: 40px;
    height: 40px;
}

.footer-contact-us-div-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-contact-text {
    color: #fff;
    text-align: start;
    font-family: "Urbanist";
    font-style: normal;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    margin: 5px 0px;
}


@media screen and (max-width: 1000px) {
    .footer-anantya-logo {
        width: 150px;
    }
}

@media screen and (max-width: 600px) {
    .footer-icons-main {
        margin: 0;
        /* padding: 0 10px; */
    }

    .design-with-love-text {
        font-size: 0.8rem;
    }

    .footer-anantya-logo {
        width: 100px;
    }

    .footer-main-row {
        flex-direction: column;
    }

    .footer-links-main {
        margin-top: 10px;
        border-top: #99ffef 1px solid;
        border-bottom: #99ffef 1px solid;
        border-right: none;
        border-left: none;
    }

    .footer-contact-us-div-col {
        margin: 10px 0px;
        flex-direction: column;
        align-items: last baseline;
        width: 100vw;
    }

    .footer-contact-us-icons {
        width: 25px;
        height: 25px;
    }
}
