@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}


/*  animation for title */



/* .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 2px 2px #000000;
  animation: pulse 2s infinite;
  margin-top:25px;
  
} */




 

.card2{
  width: 300px;
  height: 370px;
  background: radial-gradient(circle at center, #0f1834 0%, #060c21 80%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 25px;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 15px;
  margin: 2% 5% 5%;
  border: 2px solid #ffffff;
  border-radius: 8%;
  
}



 .card2 {
  transition: box-shadow 0.2s ease-in-out,transform 0.2s ease-in-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.card2:hover {
  transform: translate(-5px, -5px);
  box-shadow: 0 5px 15px rgba(0, 0, 255, 0.3);
  /* transform: rotate(5deg) */
} 

 

.developers-container-main{
  margin: 3% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header {
  display: flex;
  height:250px;
  flex-direction: column;
  align-items: center;
}
.avatar {
  width: 150px;
  height: 150px;
  position: relative;
}

img {
  transition: all 0.3s ease-in-out;
}

img:hover {
  transform: scale(0.89);
}



.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-name h1 {
  font-size: 24px;
  font-weight: 500;
  margin-top:15px;
  color: #F0F8FF;
  text-align: center; /* center the text horizontally */
  text-shadow: 1px 1px #FFFFFF; /* add a shadow effect to the text */
 
  
}
.profile-role {
    /* font-family: 'Rubik', sans-serif; */
    font-family: 'Varela Round', sans-serif;
  color: #6d6d6d;
  margin-top:15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;  
  letter-spacing: 1.5px; 
  font-size: 16px; 
  background: linear-gradient(to right, #63b8ff, #5bc0de, #5cb85c, #f0ad4e, #d9534f, #ff6a6a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 
}


.card-footer {
  width: 90%;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.developers-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:5px;
 
}

.icon-container{
    margin:0 23px;
}
/* 
.developers-buttons girish {
  width: 4px;
  height: 4px; 
  border: 0.2px solid transparent;
  border-radius: 90%; 
  font-size: 10px; 
   margin: 0 20px;
} */

.fab{
  margin: 6% 8%;
 font-size: 2rem;
 color: white;

}
